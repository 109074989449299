import React, { useState, useEffect, useMemo } from 'react'
import { useConfig } from '@peracto/peracto-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Text, Image, Link, Stack } from '@chakra-ui/core'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom'
import isObjectLike from 'lodash/isObjectLike'

// Attributes that don't start with 'attributes.*'
const UNPREFIXED_ATTRIBUTES = ['sku']

let Icon = () => (
    <Flex py={10} justifyContent="center" alignItems="center" w="100%">
        <FontAwesomeIcon icon={['far', 'box-full']} size="6x" />
    </Flex>
)

const ProductCard = ({
    product,
    isEditMode,
    productCardDirection = 'column',
    hitsDirection,
    fontColour,
    fontFamily,
    attributes,
    attributeOperator,
    isFirst,
    isLast,
    index,
    ...restProps
}) => {
    const firstImage = product.resources?.find(r => r.type === 'hero_image')

    return (
        <Stack
            className={`product-card`}
            data-testid={`product-card`}
            {...(productCardDirection === 'column' && {
                
                
            })}
            {...(productCardDirection === 'row' && {
                w: '100%',
            })}
            {...(productCardDirection === 'column' && {
                w: `100%`,
                flexBasis: { base: `100%`, md: `50%`, lg: `215px`, xl: `268px` },
            })}
            mb={productCardDirection === 'row' ? '30px' : '15px'}
            flex="1"
            ml={{
                base: 0,
                md: index % 2 === 0 ? '-0.5rem' : '0.5rem',
                lg: hitsDirection === 'row' ? (isFirst ? '-0.5rem' : '0.5rem') : 0,
            }}
            mr={{
                base: 0,
                md: index % 2 === 0 ? '0.5rem' : '-0.5rem',
                lg: hitsDirection === 'row' ? (isLast ? '-0.5rem' : '0.5rem') : 0,
            }}
            {...restProps}
        >
            <Link
                d="flex"
                flexDirection={productCardDirection}
                href={`/products/${product.slug}`}
                _hover={{textDecor: 'none'}}
                onClick={e => {
                    e.preventDefault()
                    if (isEditMode) {
                        return null
                    } else {
                        window.location = `/products/${product.slug}`
                    }
                }}
                {...(productCardDirection === 'column' && {
                    height: '100%',
                })}
            >
                <Image
                    {...(isObjectLike(product.attributes) && {
                        alt: product.attributes.product_name,
                    })}
                    src={firstImage?.location || ''}
                    w="100%"
                    flex="1"
                    maxW={productCardDirection === 'row' ? '50%' : 'none'}
                    className={`product-card__media`}
                    data-testid={`product-card__media`}
                />

                <Stack backgroundColor={product.attributes.branding_colourways} p={2}>

                    <Text
                        fontSize={`24px` }
                        lineHeight={`20px`}
                        textAlign={'center'}
                        mt={productCardDirection === 'column' ? `10px` : 0}
                        ml={productCardDirection === 'row' ? `10px` : 0}
                        fontFamily={fontFamily}
                        className={`product-card__name`}
                        data-testid={`product-card__name`}
                        flex="1"
                        color={'#fff'}
                        
                    >
                    {isObjectLike(product.attributes) && product.attributes.product_name}
                </Text>
                </Stack>
            </Link>
        </Stack>
    )
}

const Hits = props => {
    const { hits, isEditMode, productCardDirection, hitsDirection, fontColour, fontFamily } = props

    return hits.length > 0 ? (
        <>
            {hits.map((hit, index) => (
                <ProductCard
                    key={hit?.slug || `hit_${index}`}
                    product={hit}
                    isEditMode={isEditMode}
                    productCardDirection={productCardDirection}
                    hitsDirection={hitsDirection}
                    fontColour={fontColour}
                    fontFamily={fontFamily}
                    index={index}
                    isFirst={index === 0}
                    isLast={index === hits.length - 1}
                />
            ))}
        </>
    ) : isEditMode ? (
        <Icon />
    ) : null
}
const CustomHits = connectHits(Hits)

const ProductsContent = ({
    numberOfProducts = 4,
    attributes = [],
    attributeOperator = 'and',
    hitsAlignment = 'flex-start',
    hitsDirection = 'row',
    productCardDirection = 'column',
    fontColour = '#000',
    fontFamily = 'body',
    isEditMode,
}) => {
    const config = useConfig()
    const algolia = config.get('algolia') || {}

    const { applicationId, searchApiKey, indexName } = algolia

    const searchClient = algoliasearch(applicationId, searchApiKey)

    const [facetFilters, setFacetFilters] = useState()

    useEffect(() => {
        if (attributes?.length > 0) {
            const categories = attributes?.reduce((acc, { attribute, value }) => {
                const attributeKey = UNPREFIXED_ATTRIBUTES.includes(attribute)
                    ? attribute
                    : `attributes.${attribute}`
                if (value.length > 0) acc.push(`${attributeKey}:${value}`)

                return acc
            }, [])

            setFacetFilters(categories)
        }
    }, [attributes])



    return useMemo(
        () => (
            
             
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                <Configure
                    hitsPerPage={numberOfProducts}
                    facetFilters={
                        facetFilters
                            ? attributeOperator === 'and'
                                ? facetFilters
                                : [facetFilters]
                            : null
                    }
                />

                <CustomHits
                    isEditMode={isEditMode}
                    productCardDirection={productCardDirection}
                    hitsDirection={hitsDirection}
                    fontColour={fontColour}
                    fontFamily={fontFamily}
                />
            </InstantSearch>


        
        ),
        [
            facetFilters,
            numberOfProducts,
            attributeOperator,
            productCardDirection,
            fontColour,
            fontFamily,
            isEditMode,
            hitsDirection,
            indexName,
            searchClient
        ]
    )
}

const ProductsRenderer = ({ state, ...props }) => {
    const { hitsDirection = 'row', hitsAlignment } = state
    return (
        <Stack
            className="product-card-container"
            flexDirection={{
                base: 'column',
                md: hitsDirection,
            }}
            flexWrap={{
                base: 'wrap',
                lg: 'nowrap',
            }}
            justifyContent={hitsDirection === 'row' ? hitsAlignment : null}
            alignItems={hitsDirection === 'column' ? hitsAlignment : null}
            p={hitsDirection === 'row' ? '0.5rem' : 0}
        >
            <ProductsContent {...state} {...props} />
        </Stack>
    )
}

export default ProductsRenderer
