import { Container, Carousel } from "@peracto/peracto-blocks";

import BenchProducts from "./BenchProducts";

export default {
  plugins: {
    content: [Carousel, BenchProducts],
    layout: [Container],
  },
};
